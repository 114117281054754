import React, { useState, useRef } from 'react';
import Seo from 'components/seo';
import Layout from 'layouts/zh';
import ScrollBarShowLayout from 'components/ScrollBarShowLayout';
import { postToTask } from 'api/onlineDemo';
import VideoPlayer from 'components/VideoPlayer';
import classnames from 'classnames';
import useLoginDialog from 'hooks/useLoginDialog';
import { saTrackOnlineDemoButtonClick, saTrackOnlineDemoLoginClick } from 'utils/statistics';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.less'

const Realestate = () => {
  const [user, setUser] = useState(null)
  const [showDesc, setShowDesc] = useState(false)
  const curVideo = useRef<any>(null)
  const { detailsYaml } = useStaticQuery(graphql`
    query {
      detailsYaml {
         realestate_overall
         overall_catalogo_mb
         top_icon
         realestate {
          description
          experienceDemo
          experienceKPI
          keywords
          title
          saTitle
          watchVideo
          viewData {
            link
            text
          }
        }
      }
    }
  `)
  const { realestate, realestate_overall,overall_catalogo_mb,  top_icon } = detailsYaml

    // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    // 登录成功后回调
    onSuccess: () => {
      saTrackOnlineDemoLoginClick(realestate?.saTitle);
      window.location.href = '/democenter/realestate.html';
    },
  })

  const handleLink = (url, button_name) => {
    saTrackOnlineDemoButtonClick(button_name)
    url && window.open(url);
  }

  const handleToTask = (type: string) => {
    if (!user) {
     return showLoginDialog()
    }

    switch (type) {
      case 'experienceDemo':
        postToTask({name: '体验行业Demo'});
        handleLink(realestate?.experienceDemo, '体验行业Demo')
        break;
      case 'experienceKPI':
        postToTask({name: '体验指标拆解工具'});
        handleLink(realestate?.experienceKPI, '体验指标拆解工具')
        break;
      case 'viewData':
        setShowDesc(!showDesc);
        break
      case 'watchVideo':
        // @ts-ignore
        curVideo.current.playVideo();
        postToTask({ name: '观看讲解视频' });
        handleLink(false, '观看讲解视频')
        break;
      default:
    }
  }

  const renderInsurance = (<div className={styles.realestate}>
      <img width='1920px' src={realestate_overall} />
      <VideoPlayer
        videoOpt={{
          sources: realestate?.watchVideo,
        }}
        fuRef={curVideo}
      />
    <div className={classnames(styles.operationArea)}>
      <div className={styles.descContent}>
        {
          showDesc && (<div className={classnames(styles.descBg)} style={{ backgroundImage: `url(${top_icon})` }}>
          <ul className={styles.content}>
            {realestate?.viewData?.map(item => (
              <li key={item?.link} onClick={() => {
                if (!item?.link) {
                  return
                }
                postToTask({ name: '查看干货资料' });
                handleLink(item?.link, '查看干货资料')
              }}>
                <span className={styles.contentHover}>
                  {item?.text}
                </span>
              </li>
            ))}
          </ul>
        </div>)
        }
      </div>
      
      
      <div className={classnames(styles.clickArea, styles.watchVideo)} onClick={() => handleToTask('watchVideo')}/>
      <div className={classnames(styles.clickArea, styles.experienceDemo)} onClick={() => {
        handleToTask('experienceDemo')
      }} />
      <div className={classnames(styles.clickArea, styles.experienceKPI)} onClick={() => handleToTask('experienceKPI')} />
      
      <div className={classnames(styles.clickArea, styles.viewData)} onClick={() => handleToTask('viewData')}/>
    </div>
  </div>)
  return (
    <Layout showFloat={false} showCookieAuth={false} hideFooter={true}>
      <Seo
        title={realestate?.title}
        description={realestate?.descriptions}
        keywords={realestate?.keywords}
        saTitle={realestate?.saTitle}
      />
      <div className={classnames('block lg:hidden')}>
        <img width='100%' src={overall_catalogo_mb} />
      </div>
      <ScrollBarShowLayout className={classnames('hidden lg:block')} limitWidth={1920} innerH={937} childrenEle={renderInsurance} getUser={(user) => setUser(user)}/>
    </Layout> 
  )
}

export default Realestate;